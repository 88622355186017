import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

// Assets
import simulateInterviewImage from '../../assets/simulate-1.png'
import pieChartImageImage from '../../assets/pie-chart-icon-trans.png'
import barGraphImage from '../../assets/graph-icon-trans.png'
import hireBetterImage from '../../assets/hire-better-trans-2.png'

// Components
import PriceCard from './PriceCard.js'
import FAQ from './FAQ.js'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// Helpers
import { standardButton, socialMediaButtons } from '../../helpers/buttons.js'
import { positionChangeWidthSm, positionChangeWidthMd, navbarHeight, iconImageDimension, simcapTurquoise } from '../../helpers/variableDefaults.js'
import useWindowDimensions from '../../helpers/windowDimensions.js'
import { videoInsert } from '../../helpers/videos.js'
import { shuffleArray } from '../../helpers/globalHelpers.js'

import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics.js'

import heroImage1 from '../../assets/suit-man-stairs-1-2.jpg'
import heroImage2 from '../../assets/desk-woman-1-2.jpg'
import heroImage3 from '../../assets/desk-woman-2-2.jpg'
import heroImage4 from '../../assets/handshake-woman-1-2.jpg'

const heroImage = shuffleArray([
  heroImage1,
  heroImage2, 
  heroImage3, 
  heroImage4
])[0]

import whartonLogo from '../../assets/wharton-circle-1.png'
import dukeLogo from '../../assets/duke-logo-1.png'
import harvardLogo from '../../assets/harvard-1.png'
import mitLogo from '../../assets/mit-square-1.png'
import columbiaLogo from '../../assets/columbia-logo-1.png'
import cevLogo from '../../assets/cev-logo-1.png'
import marsLogo from '../../assets/mars-logo-1.png'
import greentownLabsLogo from '../../assets/greentown-labs-logo-1.png'
import icmLogo from '../../assets/icm-logo-2.png'

const userLogosArray = shuffleArray([
  whartonLogo,
  dukeLogo,
  harvardLogo,
  mitLogo,
  columbiaLogo,
  cevLogo,
  marsLogo,
  greentownLabsLogo,
  icmLogo
])

// Home Page
const Home = () => {

  // use Naviage
  const navigate = useNavigate()

  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // Navigate to /create-account when Create Account button Clicked
  const handleCreateEstatePressed = async (e) => {
    e.preventDefault()
    navigate('/create-account')
  }

  // Navigate to /demo when Demo button Clicked
  const handleDemo = async (e) => {
    e.preventDefault()
    navigate('/demo')

  }

  // Graphics Plus Text for 'Simulation Interviews; Detailed Results; Better Hires'
  const iconImagePlusText = (image, text) => {
    return (
      <>
        <Box
          sx={{
            // backgroundColor: 'whitesmoke',
            // boxShadow: 3,
            height: '100%',
            width: width > positionChangeWidthSm ? '25%' : '80%',
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {/* Image */}
          <Box
            sx={{
              height: iconImageDimension,
              width: iconImageDimension,
              backgroundImage: `url(${image})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              display: 'inline-block',
            }}
          />

          {/* Text */}
          <Typography
            sx={{
              mt: 1,
              height: '20%',
              fontWeight: 'bold',
              fontSize: '16px',
            }}
          >
            {text}
          </Typography>
        
        </Box>
      </>
    )
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Home'
      )}

      {/* Body */}
      <Box 
        sx={{ 
          height: `calc(100% - ${navbarHeight})`, width: '100vw', 
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
        }}
      >
        
        {/* Hero */}
        <Box 
          sx={{
            width: '100%', height: width > positionChangeWidthSm ? '60vh' : '70vh',
            backgroundImage: `url(${heroImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            overflow: 'hidden',
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {/* Header */}
          <Typography 
            variant="h1"
            textAlign={'center'}
            sx={{  
              // backgroundColor: 'yellow',
              width: '90%',
              fontFamily: 'Raleway',
              fontSize: '40px',
              lineHeight: '40px',
              fontWeight: 'bold',
            }}
          >
            World’s Premier VC Simulation
          </Typography>

          {/* Subheader */}
          <Typography 
            variant="h2"
            textAlign={'center'}
            sx={{  
              mt: 2,
              width: '90%',
              fontFamily: 'Raleway',
              fontSize: '25px',
              lineHeight: '25px',
              fontWeight: 'bold',
            }}
          >
            Take the seat of venture capital fund managers
          </Typography>

          {/* Create Account Button */}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {standardButton(
              'Create Account', 
              'button',
              'contained',
              false,
              'primary', 
              4,
              0,
              0,
              '180px', 
              '45px', 
              handleCreateEstatePressed
            )}
          </Box>

          {/* Demo Button */}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {standardButton(
              'See Example', 
              'button',
              'contained',
              false,
              'secondary', 
              2,
              0,
              0,
              '180px', 
              '45px', 
              handleDemo
            )}
          </Box>
        </Box>

        {/* Italicized Text and Logos */}
        <Box 
          sx={{
            // mt: 2, mb: 2,
            // backgroundColor: 'whitesmoke',
            py: 4,
            width: '100%', 
            // height: width > positionChangeWidthSm ? '225px' : '600px',
            // backgroundColor: 'yellow',
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
        >
          <Typography
            textAlign={'center'}
            sx={{
              my: 1,
              width: '90%',
              // maxWidth: '600px',
              fontStyle: 'italic',
              fontWeight: 'bold',
              fontSize: '24px',
              // color: 'darkgray',
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            }}
          >
            {`Built and rigorously tested by experienced, Partner-level VCs`}
          </Typography>

          <Typography
            textAlign={'center'}
            sx={{
              // my: 1,
              width: '90%',
              // maxWidth: '600px',
              // fontStyle: 'italic',
              fontWeight: 'bold',
              fontSize: '18px',
              // color: 'darkgray',
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            }}
          >
            {`Users Include:`}
          </Typography>

          {/* Logos */}
          <Box
            sx={{
              // mt: 1,
              width: '90%',
              // maxWidth: '900px',
              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {userLogosArray.map((userLogo, userLogoIndex) => (
              <Box
                key={userLogoIndex}
                sx={{
                  mt: 1, mx: 2,
                  // width: '20%',
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: (width < positionChangeWidthSm || userLogoIndex % 3 === 1) ? 'center' : userLogoIndex % 3 === 0 ? 'flex-end' : 'flex-start',
                  flexWrap: 'wrap',
                }}
              >
                <Box 
                  sx={{ 
                    mt: 1,
                    // backgroundColor: 'gray',
                    backgroundImage: `url(${userLogo})`,
                    width: width < 350 ? '70px' : width < 450 ? '80px' : width < 515 ? '85px' : width < 560 ? '95px' : width < 595 ? '65px' : width < 650 ? '75px' : width < 800 ? '85px' : width < 1030 ? '85px' : width < 1200 ? '105px' : '85px',
                    height: width < 350 ? '70px' : width < 450 ? '80px' : width < 515 ? '85px' : width < 560 ? '95px' : width < 595 ? '65px' : width < 650 ? '75px' : width < 800 ? '85px' : width < 1030 ? '85px' : width < 1200 ? '105px' : '85px',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    // boxShadow: 3, 
                    // borderRadius: '5px', 
                    // cursor: tapDisabled() ? '' : 'pointer',
                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
                  }}
                />
              </Box>
            ))}
          </Box>

          {/* Graphics with Text Underneath */}
          {/* <Box 
            sx={{
              // mt: 2, mb: 2,
              width: '100%', height: width > positionChangeWidthSm ? '225px' : '600px',
              // backgroundColor: 'yellow',
              display: 'flex', flexDirection: width > positionChangeWidthSm ? 'row' : 'column', justifyContent: 'space-around', alignItems: 'center',
            }}
          > */}

          {/* Simulation Interviews Image + Text */}
          {/* {iconImagePlusText(simulateInterviewImage, 'Full Immersion')} */}

          {/* Detailed Results Image + Text */}
          {/* {iconImagePlusText(barGraphImage, 'Detailed Insights')} */}

          {/* Better Hires Image + Text */}
          {/* {iconImagePlusText(hireBetterImage, 'Better Investments')} */}
          {/* </Box> */}
        </Box>


        {/* Introduction Video */}
        <Box
          sx={{
            mt: 0, 
            // mb: 4,
            width: '100%',
            // maxWidth: 'md',
            // backgroundColor: 'whitesmoke',
            backgroundColor: 'mistyrose',
            boxShadow: 3,
            // borderRadius: '5px',
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {/* <Typography
            sx={{
              pt: 3,
              width: '90%',
              maxWidth: '600px',
              fontStyle: 'italic',
              fontSize: '20px',
              // color: 'darkgray',
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            }}
          >
            {`Built and rigorously tested by experienced, Partner-level VCs`}
          </Typography> */}

          <Box
            sx={{
              pb: 6, 
              pt: 3,
              pl: 0,
              width: '100%',
              maxWidth: '600px',
            }}
          >
            {videoInsert('https://www.youtube.com/embed/oIc553voOZY', 'Introducing SimCap', '100%', width)}
          </Box>
        </Box>

        {/* Pricing */}
        {/* <Box
          sx={{
            backgroundColor: '#616161',
            boxShadow: 3,
            width: '100%',
            pb: 6, pt: 6,
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
        >
          <PriceCard price='Start Free Trial' />
        </Box> */}

        {/* FAQ */}
        <FAQ fromComponent='home' />

      </Box>
    </>
  )
}

export default Home